.modalCreateNewsContainer {
    width: 50vw;
    height: 50vh;
    min-width: 300px;
    min-height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generalContainer,
.propContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
}

.generalContainerContent,
.propContainerContent {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
}

.generalContainerSelect,
.generalContainerInput {
    flex: 1;
}

.modalInput {
    width: 100%;
    border: 1px solid;
    border-color: #d1d5db;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.35rem;
    outline: none;
    margin-top: 5px;
}

.modalInputArea {
    height: 40vh;
    resize: none;
}

.modalInput:focus {
    border-color: #155e75;
}

.inputFileHide {
    display: none;
}

.customFileButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 1px solid #155e75;
    border-radius: 7px;
    transition: transform 0.2s ease-in-out;
    background-color: #155e75;
    white-space: nowrap;
    cursor: pointer;
    align-items: center;
    color: white;
}

.fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customFileInputContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
}

.modalFooter{
    display: flex;
    justify-content: flex-end;
}

.createNewsCategoryContainer{
    display: flex;
    width: 40vw;
    flex-direction: column;
    gap: 10px;
}