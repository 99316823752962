/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App, #root, body, html {
    @apply h-full
  }

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .sidebar, .sidebar-cards {
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: auto;
    scrollbar-color: #1b4e63 #155e75;
  }

  /* Chrome, Edge, and Safari */
  .sidebar::-webkit-scrollbar, .sidebar-cards::-webkit-scrollbar {
    width: 12px;
  }

  .sidebar::-webkit-scrollbar-track, .sidebar-cards::-webkit-scrollbar-track {
    background: #155e75;
  }

  .sidebar::-webkit-scrollbar-thumb, .sidebar-cards::-webkit-scrollbar-thumb {
    background-color: #1b4e63;
    border-radius: 10px;
    border: 3px solid #155e75;
  }
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .basic-table {
    scrollbar-width: auto;
    scrollbar-color: #1b4e63 #155e75;
  }

  /* Chrome, Edge, and Safari */
  .basic-table::-webkit-scrollbar {
    width: 8px;
  }

  .basic-table::-webkit-scrollbar-track {
    background: #dee4e6;
  }

  .basic-table::-webkit-scrollbar-thumb {
    background-color: #a9b3b9;
    border-radius: 10px;
    border: 1px solid #dee4e6;
  }
