.generalContainer{
    display: flex;
    flex-direction: row;
    background-color: #f2f2f2;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    position: relative;
}

.modelInfo{
    display: flex;
    align-items: center;
    width: 15%;
    gap: 5px;
}

.addCategory{
    height: 100%;
    width: 15%;
}

.categories{
    display: flex;
    flex-direction: column;
    width: 54%;
    gap: 5px;
    margin: 5px 0px;
}

.categoryItemName{
    width: 30%;
    padding-left: 3px;
    padding-top: 3px;
}

.categoryItemContainer{
    display: flex;
    background-color: #00000010;
    padding: 3px;
    border-radius: 5px;
}

.categoryItemDates {
    display: flex;
    gap: 5px;
}

.financial{
    display: flex;
    align-items: center;
    width: 16%;
    gap: 5px;
}

.financialDiscount{
    display: flex;
    align-items: center;
    gap: 3px;
    width: 90px;
}

.financialPrice{
    display: flex;
    align-items: center;
}

.modalItemClose {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    right: 0;
    top: 0;
    padding: 5px;
    position: absolute;
}