.header{
    display: flex;
    align-items: center;
    gap: 10px;
}

.days{
    border: 0;
}

.days:focus{
    border: 0;
    outline: none !important;
}