.editModalContainer,  .createModalContainer{
    width: 30vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.selectEditModalContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.iconModalContainerContent{
    display: flex;
    gap: 5px;
    align-items: center
}

.iconModalContainerIcon{
    width: 100%;
    height: 100%;
}

.iconContainer{
    display: flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    background-color: #d1d5db32;
    border-radius: 3px;
}

.checkboxContainer{
    display: flex;
    gap: 15px;
}

.basicTableFooter{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.controlModalContainer{
    height: 55vh;
    width: 70vw;
    padding: 3px;
    overflow-x: auto;
}

.entriesContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tableFooter{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tableFooterActions{
    display: flex;
    gap: 5px;
}

.adjustDiscountContainer{
    display: flex;
    gap: 5px;
    align-items: center;
}

.selectContainer{
    display: flex;
    justify-content: space-between;
}

.modalFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addSelect{
    width: 20vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
}