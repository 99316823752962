.chatHeader{
    display: flex;
    gap: 10px;
    align-items: center;
    z-index: 1;
    position: relative;
    
}

.chatSelect{
    flex: 1;
}

.chatButton{
    margin-top: 20px;
}

.generalContainer{
    display: flex;
	align-items: center;
}

.textContainer, .textContainerInteraction {
	position: relative;
	background: #a5f3fc;
	border-radius: 0.5em;
    width: 100%;
    gap: 10px;
    display: flex;
	padding: 5px 10px;
	height: max-content;
}

.avatarMessageContainer{
    text-wrap: wrap;
    word-break: break-word;
    white-space: wrap;
    align-items: center;
    flex: 1;
}

.textContainer:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-right-color: #a5f3fc;
	border-left: 0;
	margin-top: -5px;
	margin-left: -5px;
}

.avatarNameContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.avatarName{
    font-weight: bold;
}

.chatBox{
    height: 54vh;
    overflow: auto;
    z-index: 0;
}

.viewport {
	display: flex;
	flex-direction: column;
    gap: 5px;
}

.infoIcon{
    height: 25px;
}

.avatarIcon {
	height: 32px;
    width: 32px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}