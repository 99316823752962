.slotFooter{
    display: flex;
    align-items: center;
    gap: 10px;
}

.modalModLogContainer{
    width: 30vw;
    max-height: 50vh;
    overflow-y: auto;
}

.modalValue{
    display: flex;
    flex-direction: column;
}

.modalSubtitle{
    font-weight: bold;
}

.modalText{
    word-break: break-all;
    white-space: pre-wrap;
}

.creator{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.missionDetail{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 8px;
}

.missionDetailCombo{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.missionSubtitle{
    font-weight: bold;
}

.missionFee{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.userHeader{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.slotHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slotHeaderBtn{
    margin-right: 8px;
}

.modalSlotHeaderTitle{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalSlotHeaderBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 10px;
}

.slotAbuseReportFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.slotAbuseReportBtnsFooter{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}