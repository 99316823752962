.modalContainer{
    width: 40vw;
}

.tableContainer{
    width: 100%;
    height: 50vh;
    overflow-y: auto;
    scrollbar-width: auto;
    scrollbar-color: #1b4e63 #155e75;
}

.modalFooter{
    display: flex;
    justify-content: flex-end;
}