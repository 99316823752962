.corpo{
    display: flex;
    width: 40vw;
    flex-direction: column;
}

.separator{
    width: 100%;
    height: 1px;
    background-color: #9095a0;
    margin: 10px 0px 10px 0px;
}

.header{
    font-weight: bold;
}

.from{
    font-size: 13px;
}

.message{
    white-space: pre-wrap;
}