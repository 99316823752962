.createModalContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 40vw;
}

.createGroupNameContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
}

.createGroupInfoContainer{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
}

.createGroupTokenContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    
}

.createGroupCheckboxContainer{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}

.createGroupTokenDurationContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.createGroupDurationContainer{
    display: flex;
    gap: 5px;
}

.createGroupDuration{
    display: flex;
    align-items: center;
    gap: 8px;
    width: 10vw;
}

.modalInput {
    width: 40%;
    border: 1px solid;
    border-color: #d1d5db;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    outline: none;
    margin-top: 5px;
}

.modalInput:focus {
    border-color: #155e75;
}

.createInterstitialDatesContainer, .createInterstitialUrlContainer{
    display: flex;
    width: 100%;
    gap: 8px;
}

.createInterstitialDateContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.createInterstitialImgContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}
