.generalContainer{
    display: flex;
    flex-direction: row;
    background-color: #f2f2f2;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    justify-content: center;
}

.modelInfo{
    display: flex;
    align-items: center;
    width: 20%;
    gap: 5px;
}

.iconContainer{
    background-color: pink;
    width: 40%;
}

.spaceNameContainer{
    flex: 1;
}

.icon{
    width: 100%;
}

.addCategory{
    height: 100%;
    width: 20%;
}

.categories{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 5px;
    margin: 5px 0px;
}

.categoryItemName{
    width: 35%;
    padding-left: 3px;
    padding-top: 3px;
}

.categoryItemContainer{
    display: flex;
    background-color: #00000010;
    padding: 3px;
    border-radius: 5px;
}

.categoryItemDates {
    display: flex;
    gap: 5px;
}

.modalItemClose {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    right: 0;
    top: 0;
    padding: 5px;
    position: absolute;
}