.proofModalContainer {
    height: 50vh;
    display: flex;
    width: 45vw;
}

.proofModalContainerSpin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.proofModalControler {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.container {
    width: 45vw;
}

.filterContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.infoContainer, .transactionInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    height: 44vh;
    overflow-y: auto;
    /* Ativar overflow vertical */
    overflow-x: hidden;
    /* Ocultar overflow horizontal */
    box-sizing: border-box;
}

.manageChatLog {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.message{
    flex: 1;
    display: flex;
    gap: 10px;
    width: 100%;
}

.messageDate{
    width: auto;
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.messageContent{
    flex: 1;
}

.infractionModalContainer{
    display: flex;
    width: 30vw;
    flex-direction: column;
}

.pointsInfo {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.userInfractionModalControler, .infractionModalContainerUser{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}

.infractionModalContainerUser{
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center;
}