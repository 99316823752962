.initialState{
    display: grid;
    width: 100%;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms;
}

.endState{
    display: grid;
    width: 100%;
    grid-template-rows: 1fr;
    transition: grid-template-rows 300ms;
}