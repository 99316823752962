.header{
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
}

.userGroups {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.authBalance {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    padding-left: 10px;
    width: 100%;
}

.balance {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    padding-left: 10px;
    width: 100%;
}

.select, .multipleSelect {
    position: relative;
    width: 100%;
    cursor: default;
    border: 1px solid rgba(57, 69, 71, 0.3);
    border-radius: 6px;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 40px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.08);
    font-size: 1rem;
    outline: none;
    margin-top: 5px;
}

.select>option {
    position: absolute;
    margin-top: 4px;
    max-height: 192px;
    width: 100%;
    overflow: auto;
    border-radius: 6px;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 1rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1),
        0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
    outline: none;
}

.modalContentArea{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.modalTableContent{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 40vw;
}

.modalTableContentTop{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modalTableContentTopInputs, .modalTableContentTopBtn{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.modalTableContentTopBtn{
    margin-bottom: 10px;
}

.modalTableContentTopSelect{
    flex: 2;
}

.modalTableContentTopInput{
    flex: 1;
}

.modalInputArea{
    width: 50vw !important;
    height: 40vh;
}



.modalButton, .modalImgButton{
    padding: 5px 10px;
    border: 1px solid #c1c5ca;
    border-radius: 7px;
    transition: transform 0.2s ease-in-out;
    margin-top: 10px;
}

.modalButton:hover, .modalImgButton:hover {
    transform: scale(1.1);
}

.modalButton:active, .modalImgButton:active {
    transform: scale(0.9);
}

.modalInput {
    width: 100%;
    border: 1px solid;
    border-color: #d1d5db;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    outline: none;
    margin-top: 5px;
}

.modalInput:focus {
    border-color: #155e75;
}

.modalLabel, .membersModalLabel, .modalImgLabel {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #111827;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.balanceItem {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.balanceGroup {
    display: flex;
    flex-direction: column;
}

.balanceUpdate {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.balanceUpdateInput {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 2;
}

.balanceUpdateInput>input {
    height: 15px;
    font-size: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
}

.balanceUpdateInput>input:hover {
    border-color: #9a9da1;
}

.balanceUpdateInput>input:focus {
    outline: none;
    border-color: #9a9da1;
    box-shadow: 0 0 0 0px #ffffff00;
}

.updateInputVal {
    width: 40%;
}

.updateInputRes {
    width: 60%;
}

.balanceUpdateBtn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 20%;
    flex: 1;
    justify-content: center;
}

.balanceItem>span {
    color: rgb(17 24 39 / 1);
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.25rem 0.5rem;
}

.balanceData {
    display: flex;
    flex: 1;
    width: 100%;
}

.authContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}

.btnContainer {
    display: flex;
    flex: 1;
    gap: 10px;
    margin-left: 2vw;
}

.subTitle {
    margin-bottom: 10px;
}

.btnIcon {
    display: flex;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: white;
    transition: transform 0.2s ease-in-out;
    padding-top: -5px;
    position: relative;
}

.btnIcon:hover {
    transform: scale(1.1);
}

.btnIcon:active {
    transform: scale(0.9);
}

.btnIcon:focus-visible {

    outline-offset: 2px;
}

.btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease-in-out;
    position: relative;
}

.btn:hover {
    transform: scale(1.1);
}

.btn:active {
    transform: scale(0.9);
}


.btn:focus-visible {
    outline-offset: 2px;
}

.btn:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.btn>span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.btnIcon>span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.modNotesHeader{
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 
                0 4px 6px -2px rgba(0, 0, 0, 0.05),
                0 0 0 2px rgba(0, 0, 0, 0.1); 
    border-radius: 0.5rem;
    margin-bottom: 10px;
}

.avatarHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.avatarSkillsContainer{
    width: 100%;
    margin-top: 10px;
}

.avatarSkillsTopContainerRow{
    display: flex;
}

.avatarSkillsTopContainerRow > div {
    flex: 1;
}

.skillItem{
    display: flex;
    margin: 5px 0;
}

.skillIcon{
    height: 20px;
    margin-right: 5px;
}

.skillPrimaryIcon{
    height: 15px;
}
.skillTitle{
    display: flex;
    width: 8.5vw;
    font-size: 13px;
}

.skillInfo{
    display: flex;
    width: 8vw;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
}

.skillInfoMiddle{
    margin: 0 10px;
}

.skillInfoNum {
    font-weight: bold;
}

.skillInput {
    height: 15px;
    font-size: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    width: 60px;
}

.skillInput:hover {
    border-color: #9a9da1;
}

.skillInput:focus {
    outline: none;
    border-color: #9a9da1;
    box-shadow: 0 0 0 0px #ffffff00;
}

.skillInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.skillInput {
    -moz-appearance: textfield;
}

.skillXp{
    font-weight: 500;
}

.membersModalLabel, .modalImgLabel{
    margin-top: 10px !important;
}

.memberModalButtons{
    display: flex;
    gap:10px
}

.modalImg{
    height: 150px;
}

.modalImgContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputFile{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.inputFile::file-selector-button{
    padding: 5px 10px;
    border: 1px solid #155e75;
    border-radius: 7px;
    transition: transform 0.2s ease-in-out;
    margin-top: 10px;
    background-color: #155e75;
    cursor: pointer;
    align-items: center;
    color: white;
}

.modalImgButton{
    width: 90%;
}
